import React, { useState, useContext, useEffect, useMemo } from "react";
import "../Header/HeaderNav.css";
import StateContext from "../../context/stateGlobal/StateContext";
import axios from "axios";
import Cookies from "universal-cookie";
import CryptoJS from "crypto-js";
import { Link, useParams, useNavigate } from "react-router-dom";
import Login from "./Login";
import DownloadReport from "../DownloadReport/DownloadReport";
import HomeIcon from "@mui/icons-material/Home";
import * as THREE from "three";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import PDFInfoModal from "../PDFInfoModal/PDFInfoModal";
import handleToothClick, { resetToothSelection } from "../Product3D/handleToothClick";

const cookies = new Cookies();

export default function HeaderNavRight({ setToggleBtnMenu, appDivRef }) {
	let publicUrl = process.env.PUBLIC_URL;
	const baseUrl = process.env.REACT_APP_BASE_URL;

	const globalState = useContext(StateContext);
	const [showMessage, setShowMessage] = useState(false);
	// const [userName, setUserName] = useState("");
	const [shareUrl, setShareUrl] = useState("");
	const [showLogin, setShowLogin] = useState(false);
	const [patientName, setPatientName] = useState("");
	const [patientLowerData, setPatientLowerData] = useState(null);
	const [patientUpperData, setPatientUpperData] = useState(null);
	const [patientIPRData, setPatientIPRData] = useState(false);
	const [patientIPRJsonData, setPatientIPRJsonData] = useState([]);
	const [doctorName, setDoctorName] = useState(null);
	const [helpFormData, setHelpFormData] = useState({
		message: "",
		mobileNumber: "",
	});
	const [showPdfModal, setShowPdfModal] = useState(false);
	const [isGenerating, setIsGenerating] = useState(false);
	const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);

	const [selectedTooth, setSelectedTooth] = useState(null);
	const [firstSelectedTooth, setFirstSelectedTooth] = useState(null);

	const navigate = useNavigate();

	const { patientId, caseId, uuid } = useParams();
	const upperJawsIprData =
		Array.isArray(patientIPRJsonData) &&
		patientIPRJsonData.filter((item) => item.jaw === "upper").length > 0;
	const lowerJawsIprData =
		Array.isArray(patientIPRJsonData) &&
		patientIPRJsonData.filter((item) => item.jaw === "lower").length > 0;
	const handleClick = () => {
		setShowMessage(!showMessage);
	};
	const strapiUrl = "https://pacs-auth.dent-scan.com";
	useEffect(() => {
		if (globalState.isLoggedIn && globalState.addingIprDataBool) {
			globalState.setIsLoggedInForIpr(true);
			globalState.playPauseRef.current.style.display = "none";
		} else if (!globalState.isLoggedIn && globalState.addingIprDataBool) {
			globalState.setIsLoggedInForIpr(false);
			globalState.playPauseRef.current.style.display = "none";
			if (globalState.viewer) {
				if (globalState.viewer.scene) {
					while (globalState.viewer.scene.getObjectByName("iprGroup")) {
						globalState.actions.removeObjWithChildren(
							globalState.viewer.scene.getObjectByName("iprGroup"),
						);
					}
				}
				globalState.actions.disposeThreeViewer(globalState.viewer);
			}
			globalState.setIsFileDropped(false);
			globalState.footerRef.current.style.display = "none";
		}
	}, [globalState.isLoggedIn, globalState.addingIprDataBool]);

	useEffect(() => {
		let jwtToken = cookies.get("jwtToken", { path: "/" });
		let userName = cookies.get("userName", { path: "/" });
		if (jwtToken) {
			globalState.setIsLoggedIn(true);
			if (userName) {
				// setUserName(userName);
				if (globalState.tempTreatmentData) {
					// let currentTreatmentPlan =
					// 	globalState.selectedTreatmentPlan - 1;
					let currentTreatmentPlan = globalState.tempTreatmentData.find(
						(plan) =>
							parseInt(plan.attributes.treatment_plan_version) ===
							globalState.selectedTreatmentPlan,
					);

					let patientData = currentTreatmentPlan.attributes;
					setPatientName(patientData.patient_name);
					setPatientLowerData(patientData.lower_steps_number);
					setPatientUpperData(patientData.upper_steps_number);
					setPatientIPRData(patientData.ipr_data);
					setPatientIPRJsonData(patientData.ipr_data);
					setDoctorName(patientData.doctor_name);
				}
			}
			userRole({ token: jwtToken });
		} else {
			globalState.setIsLoggedIn(false);
		}
	}, [
		globalState.tempTreatmentData,
		shareUrl,
		globalState.isLoggedIn,
		upperJawsIprData,
		lowerJawsIprData,
	]);

	// Define setBetweenValue function
	const setBetweenValue = (value) => {
		if (globalState.iprBetweenRef.current) {
			globalState.iprBetweenRef.current.value = value;
		}
	};

	// Define getAttachment function
	const getAttachment = () => {
		return (
			globalState.viewer?.scene?.getObjectsByProperty(
				"customType",
				"attachment",
			).length > 0
		);
	};

	useEffect(() => {
		if (!window.threeViewer || !window.threeViewer.renderer || !globalState.addingIprDataBool) return;

		const handleToothClickWrapper = (event) => 
			handleToothClick(
				event, 
				window.threeViewer, 
				setSelectedTooth, 
				setBetweenValue, 
				firstSelectedTooth, 
				setFirstSelectedTooth // Pass state and state setter for first selected tooth
			);

		window.threeViewer.renderer.domElement.addEventListener('dblclick', handleToothClickWrapper);

		return () => {
			if (window.threeViewer && window.threeViewer.renderer) {
				window.threeViewer.renderer.domElement.removeEventListener('dblclick', handleToothClickWrapper);
			}
		};
	}, [window.threeViewer, firstSelectedTooth]);

	const isAttachment = useMemo(() => getAttachment(), [globalState.viewer]);

	const userRole = async ({ token }) => {
		axios
			.get(`${strapiUrl}/api/users/me?populate=role`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
				if (!res.data.blocked) {
					globalState.setTreatmentData(globalState.tempTreatmentData);
					// setIsLoginOpen(false);
					// appDivRef.current.classList.remove("overlay");
				}
				return res.data;
			})
			.catch((error) => {
				if (error.response.status === 401) {
					cookies.remove("jwtToken", { path: "/" });
					cookies.remove("userName", { path: "/" });
					cookies.remove("userRole", { path: "/" });
					globalState.setIsLoggedIn(false);
				}
			});
	};

	const treatmentPlanChange = (e) => {
		// treatmentUiId is the id of the selected treatment selected by the user
		let treatmentUiId = e.target.value;
		const selectedTreatmentData = globalState.treatmentData.find(
			(data) => data.attributes.treatment_plan_version === treatmentUiId,
		);
		let url = selectedTreatmentData.attributes.link;
		if (process.env.NODE_ENV === "development") {
			url = `http://localhost:3000/${patientId}/${treatmentUiId}/${
				url.split("/").slice(-1)[0]
			}`;
		}
		// Redirect to new url
		window.location.replace(url);
	};

	const handleShareLink = () => {
		const payload = {
			patient_id: patientId,
			treatment_plan_version: caseId,
			uuid: uuid,
			exp:
				Math.floor(Date.now() / 1000) +
				process.env.REACT_APP_SHARE_LINK_EXPIRY_DAY * 24 * 3600, // Set expiration time to 1 hour from now 3600
		};
		const token = encodeURIComponent(
			CryptoJS.AES.encrypt(
				JSON.stringify(payload),
				process.env.REACT_APP_TOKEN_SECRET_KEY,
			).toString(),
		);
		setShareUrl(`${window.location.origin}/share/${token}`);
	};

	const handleCopyClick = () => {
		let textArea = document.getElementById("shareUrl");
		textArea.select();
		document.execCommand("copy");
		textArea.blur();
		alert("Link copied to clipboard");
	};

	const handleLoginButton = () => {
		// globalState.setIsLoggedIn(true);
		setShowLogin(true);
		appDivRef.current.classList.add("overlay");

		setTimeout(() => {
			if (uuid) {
				globalState.viewer.onWindowResize(globalState.viewer);
			}
		}, 100);
	};

	const isLogout = async () => {
		cookies.remove("jwtToken", { path: "/" });
		cookies.remove("userName", { path: "/" });
		cookies.remove("userRole", { path: "/" });
		globalState.setIsLoggedIn(false);
		setShowLogin(false);
		setTimeout(() => {
			if (uuid) {
				globalState.viewer.onWindowResize(globalState.viewer);
			}
		}, 100);
		// appDivRef.current.classList.remove("overlay");
	};

	const handleHelpSubmit = () => {
		// console.log(baseUrl, patientId, caseId, uuid);
		let data = {
			doctorName: doctorName,
			patientName: patientName,
			treatmentPlanVersion: caseId,
			viewerUrl: `${baseUrl}/${patientId}/${caseId}/${uuid}`,
			message: helpFormData.message,
			mobileNumber: helpFormData.mobileNumber,
		};
		// console.log(data)
		if (!globalState.isLoggedIn) return;
		axios
			.post("https://notify.dent-scan.com/send-email", data, {
				headers: {
					"Content-Type": "application/json",
					"api-key": "45dcd151-964f-4683-be0e-504de51c3011",
				},
			})
			.then((response) => {
				document.getElementById("closeModalButton").click();
				alert("Request sent. We will get back to you shortly");
			})
			.catch((error) => {
				if (error.response.data.errors.length > 0) {
					alert(error.response.data.errors[0].msg);
				} else {
					alert("error sending email. Please try again later");
				}
			})
			.finally(() => {
				setHelpFormData({
					message: "",
					mobileNumber: "",
				});
			});
	};

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setHelpFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const captureThreeViews = async (viewer) => {
		// Store original camera state
		const originalPosition = viewer.camera.position.clone();
		const originalRotation = viewer.camera.rotation.clone();
		const originalZoom = viewer.camera.zoom;
		const originalControlsEnabled = viewer.controls.enabled;
		// Temporarily disable controls and set background to white
		viewer.controls.enabled = false;
		viewer.scene.background = new THREE.Color("white"); // Set background color to white
		
		//Hide IPR during capture
		const Ipr = window.threeViewer.scene.getObjectByName("iprGroup");
		
		if (Ipr) {
			const isVisible = Ipr.visible;
			Ipr.visible = !isVisible;
		}
		const images = [];
		const views = [
			{
				name: "Right View",
				position: new THREE.Vector3(50, 0, 0),
				rotation: new THREE.Euler(0, -Math.PI / 2, 0, "XYZ"), // Added 'XYZ' order
			},
			{
				name: "Front View",
				position: new THREE.Vector3(0, 0, 50),
				rotation: new THREE.Euler(0, 0, 0, "XYZ"), // Added 'XYZ' order
			},
			{
				name: "Left View",
				position: new THREE.Vector3(-50, 0, 0),
				rotation: new THREE.Euler(0, Math.PI / 2, 0, "XYZ"), // Added 'XYZ' order
			},
		];
		try {
			for (const view of views) {
				// Update camera
				viewer.camera.position.copy(view.position);
				viewer.camera.rotation.copy(view.rotation);
				viewer.camera.lookAt(0, 0, 0);
				viewer.camera.updateProjectionMatrix();

				// Need to wait for the scene to update
				await new Promise((resolve) => setTimeout(resolve, 500));

				// Render and capture
				viewer.renderer.render(viewer.scene, viewer.camera);
				images.push({
					name: view.name,
					dataUrl: viewer.renderer.domElement.toDataURL("image/png"),
				});
			}
		} finally {
			// Restore original camera state
			viewer.camera.position.copy(originalPosition);
			viewer.camera.rotation.copy(originalRotation);
			viewer.camera.zoom = originalZoom;
			viewer.camera.updateProjectionMatrix();
			viewer.controls.enabled = originalControlsEnabled;
			viewer.scene.background = new THREE.Color("rgb(112, 114, 115)"); // Restore original background color
			
			//Show IPR After capture
			if (Ipr) {
				const isVisible = Ipr.visible;
				Ipr.visible = !isVisible;
			}
			viewer.renderer.render(viewer.scene, viewer.camera);
		}
		return images;
	};
	// Modify the generatePDF function to use the panoramic images
	const generatePDF = async (viewer, jsonData, pdfInfo) => {
		try {
			const images = await captureThreeViews(viewer);
			const doc = new jsPDF();
			const pageWidth = doc.internal.pageSize.width;
			const pageHeight = doc.internal.pageSize.height;

			// Function to add page border and number
			const addPageBorderAndNumber = (pageNum, totalPages) => {
				// Add border
				doc.setDrawColor(0);
				doc.setLineWidth(0.5);
				doc.rect(10, 10, pageWidth - 20, pageHeight - 20);

				// Add page number
				doc.setFontSize(10);
				doc.setTextColor(100);
				doc.text(
					`Page ${pageNum}/${totalPages}`,
					pageWidth / 2,
					pageHeight - 15,
					{ align: "center" },
				);
			};

			// Calculate total pages (1 for views + 1 for IPR if exists)
			const totalPages =
				globalState.iprJsonObj && Array.isArray(globalState.iprJsonObj) ? 2 : 1;

			// First page with border and page number
			addPageBorderAndNumber(1, totalPages);

			// Header section with optimized spacing
			doc.setFontSize(10);
			doc.setTextColor(100);
			const currentDate = new Date().toLocaleDateString();
			doc.setFont(undefined, "bold");
			doc.text(`Date: `, 15, 20);
			doc.text(`Patient ID: `, 15, 25);
			doc.text(`Patient Name: `, 15, 30);
			doc.text(`Doctor Name: `, 15, 35);
			doc.text(`Plan version: `, 15, 40);
			doc.setFont(undefined, "normal");
			doc.text(`${currentDate}`, 40, 20);
			doc.text(`${pdfInfo.patientUploadId}`, 40, 25);
			doc.text(`${pdfInfo.patientUploadName}`, 40, 30);
			doc.text(`${pdfInfo.doctorUploadName}`, 40, 35);
			doc.text(`${pdfInfo.treatmentPlanVersionUpload}`, 40, 40);

			// Logo positioned higher and more to the right
			doc.addImage(
				`${process.env.PUBLIC_URL}/Logo.png`,
				"PNG",
				pageWidth - 60,
				15,
				45,
				15,
			);

			// Main title with larger font and better spacing
			doc.setFontSize(14);
			doc.setTextColor(0);
			doc.setFont(undefined, "bold");
			doc.text("Attachment Views", pageWidth / 2, 50, { align: "center" });
			doc.setFont(undefined, "normal");

			// Optimized image layout with better spacing
			const margin = 15;
			const availableWidth = pageWidth - 2 * margin;
			const imageWidth = availableWidth * 0.85; // 85% of available width
			const imageHeight = imageWidth * 0.45; // Maintain aspect ratio
			const startY = 60; // Start images lower to accommodate header
			const spaceBetweenImages = 0; // Reduced space between images

			// Place images one below another with optimized spacing
			images.forEach((image, index) => {
				const yPosition = startY + index * (imageHeight + spaceBetweenImages);
				const xPosition = (pageWidth - imageWidth) / 2;

				// View label with smaller font

				// doc.setFontSize(11);
				// doc.setFont(undefined, "bold");
				// doc.text(image.name, pageWidth / 2, yPosition - 2, { align: "center" });
				// doc.setFont(undefined, "normal");

				// Add image with slight border

				// doc.setDrawColor(200);
				// doc.setLineWidth(0.1);
				// doc.rect(xPosition, yPosition, imageWidth, imageHeight);

				doc.addImage(
					image.dataUrl,
					"PNG",
					xPosition,
					yPosition,
					imageWidth,
					imageHeight,
					undefined,
					"FAST",
				);
			});

			// IPR Data section with consistent styling
			if (globalState.iprJsonObj && Array.isArray(globalState.iprJsonObj)) {
				doc.addPage();
				addPageBorderAndNumber(2, totalPages);

				doc.setFontSize(14);
				doc.setFont(undefined, "bold");
				doc.text("IPR Data", pageWidth / 2, 25, { align: "center" });
				doc.setFont(undefined, "normal");

				const upperJawData = globalState.iprJsonObj.filter(
					(item) => item.jaw === "upper",
				);
				
				const lowerJawData = globalState.iprJsonObj.filter(
					(item) => item.jaw === "lower",
				);

				let tableStartY = 40;
				let emptyUpperTableStartY = 60;
				// Add tooth numbering system image
				if (upperJawData.length > 0 || lowerJawData.length > 0) {
					doc.addImage(
						`${process.env.PUBLIC_URL}/toothNumbering.jpg`,
						"JPEG",
						pageWidth / 2 - 60,  // Center the image horizontally
						30,                  // Position below the title
						120,                 // Width of the image
						40,                  // Height of the image
						undefined,
						"FAST"
					);
					// Add spacing after the image
					tableStartY = 80; // Position table below the image
					emptyUpperTableStartY = 100;
				}

				// Upper Jaw IPR Data
				
				doc.setFontSize(12);
				doc.setFont(undefined, "bold");
				doc.text("Upper Jaw", 15, (tableStartY - 5));
				doc.setFont(undefined, "normal");
				if (upperJawData.length > 0) {
					doc.autoTable({
						startY: tableStartY,
						margin: { left: 15, right: 15 },
						head: [["Sr. No", "Jaw", "Note", "Step No.", "Value", "Between"]],
						body: upperJawData.map((item, index) => [
							index + 1,
							item.jaw,
							item.note,
							item.step_no,
							item.value,
							item.between,
						]),
						styles: { fontSize: 10 },
						headStyles: { fillColor: [100, 100, 100] },
					});
					
					// Calculate and display upper jaw total
					const upperTotal = upperJawData.reduce((sum, item) => sum + parseFloat(item.value || 0), 0);
					doc.setFontSize(10);
					doc.setFont(undefined, "bold");
					doc.text(`Total Upper IPR: ${upperTotal.toFixed(2)} mm`, 15, doc.previousAutoTable.finalY + 5);
					doc.setFont(undefined, "normal");
					
				} else {
					doc.setFontSize(10);
					doc.text("No Upper Jaw IPR Data Available", 15, (tableStartY + 5));
				}

				// Lower Jaw IPR Data
				
				const startY =
					upperJawData.length > 0 ? doc.previousAutoTable.finalY + 20 : emptyUpperTableStartY + 5;

				doc.setFontSize(12);
				doc.setFont(undefined, "bold");
				doc.text("Lower Jaw", 15, startY - 5);
				doc.setFont(undefined, "normal");

				if (lowerJawData.length > 0) {
					doc.autoTable({
						startY: startY,
						margin: { left: 15, right: 15 },
						head: [["Sr. No", "Jaw", "Note", "Step No.", "Value", "Between"]],
						body: lowerJawData.map((item, index) => [
							index + 1,
							item.jaw,
							item.note,
							item.step_no,
							item.value,
							item.between,
						]),
						styles: { fontSize: 10 },
						headStyles: { fillColor: [100, 100, 100] },
					});
					
					// Calculate and display lower jaw total
					const lowerTotal = lowerJawData.reduce((sum, item) => sum + parseFloat(item.value || 0), 0);
					doc.setFontSize(10);
					doc.setFont(undefined, "bold");
					doc.text(`Total Lower IPR: ${lowerTotal.toFixed(2)} mm`, 15, doc.previousAutoTable.finalY + 5);
					doc.setFont(undefined, "normal");
					
				} else {
					doc.setFontSize(10);
					doc.text("No Lower Jaw IPR Data Available", 15, startY + 5);
				}
			}

			doc.save(`${pdfInfo.patientUploadName} IPR-Attachment Report.pdf`);
		} catch (error) {
			console.error("Error generating PDF:", error);
			throw error;
		}
	};
	// Update the handlePrintPDF function to handle errors
	const handlePrintPDF = () => {
		if (globalState.viewer) {
			setShowPdfModal(true);
		}
	};

	const handlePdfSubmit = async (formData) => {
		setIsGenerating(true);
		try {
			await globalState.actions.generateJsonData(globalState);
			await generatePDF(globalState.viewer, globalState.iprJsonObj, formData);
			setShowPdfModal(false);
		} catch (error) {
			console.error("Error generating PDF:", error);
			alert("Error generating PDF. Please try again.");
		} finally {
			setIsGenerating(false);
		}
	};

	const toggleUpperJawVisibility = () => {
		const upperJaw = window.threeViewer.scene.getObjectByName("maxillary");
		console.log(window.threeViewer.scene)
		if (upperJaw) {
			const isVisible = upperJaw.visible;
			upperJaw.visible = !isVisible;
		}
	};

	const toggleLowerJawVisibility = () => {
		const lowerJaw = window.threeViewer.scene.getObjectByName("mandibular");
		
		if (lowerJaw) {
			const isVisible = lowerJaw.visible;
			lowerJaw.visible = !isVisible;
		}
	};
	const toggleIprVisibility = () => {
		const Ipr = window.threeViewer.scene.getObjectByName("iprGroup");
		
		if (Ipr) {
			const isVisible = Ipr.visible;
			Ipr.visible = !isVisible;
		}
	};

	return (
		<>
			<div className="HeaderNav-Right">
				<div className="HeaderNav-Right-List">
					<div className="HeaderNav-Right-Inner">
						{/* console.log({globalState.iprData}); */}
						{globalState.isLoggedIn && uuid && (
							<div className="HeaderNav-user">
								<div className="HeaderNav-userBox">
									<div className="HeaderNav-userBox__col HeaderNav-userBox__col--name">
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">
												Patient:{" "}
											</span>
											<span
												className="HeaderNav-userBox__value"
												title={patientName ? patientName : "Unknown"}
											>
												{patientName ? patientName : "Unknown"}
											</span>
										</div>
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">Doctor: </span>
											<span className="HeaderNav-userBox__value">
												{doctorName ? doctorName : "Unknown"}
											</span>
										</div>
									</div>
									<div className="HeaderNav-userBox__col">
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">IPR: </span>
											<span className="HeaderNav-userBox__value">
												{patientIPRData ? "Yes" : "No"}
											</span>
										</div>
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">
												Attachments:{" "}
											</span>
											<span className="HeaderNav-userBox__value">
												{isAttachment ? "Yes" : "No"}
											</span>
										</div>
									</div>
									<div className="HeaderNav-userBox__col">
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">Upper: </span>
											<span className="HeaderNav-userBox__value">
												{patientUpperData ? patientUpperData : "N/A"}
											</span>
										</div>
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">Lower: </span>
											<span className="HeaderNav-userBox__value">
												{patientLowerData ? patientLowerData : "N/A"}
											</span>
										</div>
									</div>
								</div>

								<div className="HeaderNav-download">
									<DownloadReport />
								</div>
							</div>
						)}
						<div className="HeaderNav-dropdown">
							<button
								className="toggle-btn"
								onClick={() => {
									setToggleBtnMenu(true);
									appDivRef.current.classList.add("overlay");
								}}
							>
								<img src={`${publicUrl}/assets/img/bar.svg`} alt="bar"></img>
							</button>
							{(globalState.isLoggedIn && uuid && (
								<select
									className="form-select"
									aria-label="Default select example"
									onChange={treatmentPlanChange}
									value={globalState.selectedTreatmentPlan || ""}
								>
									{globalState.treatmentData &&
										globalState.treatmentData.map((item, index) => {
											let treatmentId = item.attributes.treatment_plan_version;
											return (
												<option key={index} value={treatmentId}>
													Treatment Plan {treatmentId}
												</option>
											);
										})}
								</select>
							)) ||
								(uuid && (
									<div className="treatment-ipr-data">
										Treatment Plan {caseId}
									</div>
								))}
						</div>
						{globalState.isLoggedIn && uuid && (
							<>
								<div className="popup homeIcon" title="Home">
									<button
										className="HeaderNav-Msg-Btn"
										onClick={() => navigate("/", { replace: true })}
									>
										<i className="fa-solid fa-house"></i>
										<span className="shareText">Home</span>
									</button>
								</div>
								<div
									className="helpButton"
									// onClick={handleClick}
								>
									{patientIPRData && (
										<button
											className="HeaderNav-Msg-Btn showIprBtn"
											data-bs-toggle="modal"
											data-bs-target="#staticBackdropIPR"
										>
											{/* <i className="fa-solid fa-message btnIcon"></i> */}
											<i className="fa-solid fa-file btnIcon"></i>
											Show IPR
										</button>
									)}
									<button
										className="HeaderNav-Msg-Btn"
										data-bs-toggle="modal"
										data-bs-target="#staticBackdropHelp"
										id="closeModalButton"
									>
										<i className="fa-solid fa-message btnIcon"></i>
										Contact us
									</button>
									{showMessage && (
										<span className="popuptext" id="myPopup">
											Lorem Ipsum is simply dummy text of the printing and
											typesetting industry. Lorem Ipsum has been the industry's
											standard dummy text ever since the 1500s, when an unknown
											printer took a galley of type and scrambled it to make a
											type specimen book. It has survived not only five
											centuries, but also the leap into electronic typesetting,
											remaining essentially unchanged. It was popularised in the
											1960s with the release of Letraset sheets containing Lorem
											Ipsum passages, and more recently with desktop
											<p className="date-meg">Dent scan 18/09/2023 16:20</p>
										</span>
									)}
								</div>
								<div
									className="modal fade"
									id="staticBackdropHelp"
									data-bs-backdrop="static"
									data-bs-keyboard="false"
									tabIndex="-1"
									aria-labelledby="staticBackdropHelpLabel"
									aria-hidden="true"
								>
									<div className="modal-dialog modal-dialog-centered">
										<div className="modal-content">
											<div className="modal-header">
												<button
													type="button"
													className="btn-close"
													data-bs-dismiss="modal"
													aria-label="Close"
												></button>
											</div>

											<div className="modal-body">
												<input
													type="text"
													id="helpPhone"
													name="mobileNumber"
													className="form-control mb-2"
													placeholder="Mobile Number"
													value={helpFormData.mobileNumber}
													onChange={handleInputChange}
												/>
												<textarea
													id="helpMessage"
													name="message"
													className="form-control"
													placeholder="Message"
													value={helpFormData.message}
													onChange={handleInputChange}
												></textarea>
											</div>
											<div className="modal-footer">
												<button
													type="button"
													className="button"
													onClick={handleHelpSubmit}
												>
													Send
												</button>
											</div>
										</div>
									</div>
								</div>

								<div
									className="modal fade"
									id="staticBackdropIPR"
									data-bs-backdrop="static"
									data-bs-keyboard="false"
									tabIndex="-1"
									aria-labelledby="staticBackdropHelpLabel"
									aria-hidden="true"
								>
									<div className="modal-dialog modal-dialog-centered">
										<div className="modal-content">
											<div className="modal-header">
												<button
													type="button"
													className="btn-close"
													data-bs-dismiss="modal"
													aria-label="Close"
												></button>
											</div>

											<div className="modal-body">
												<div>
													{upperJawsIprData ? (
														<>
															<h6>Upper Jaw</h6>
															<table className="table">
																<thead>
																	<tr>
																		<th>Sr. No</th>
																		<th>Jaw</th>
																		<th>Note</th>
																		<th>Step No.</th>
																		<th>Value</th>
																		<th>Between</th>
																	</tr>
																</thead>
																<tbody>
																	{patientIPRJsonData &&
																		patientIPRJsonData
																			.filter((item) => item.jaw === "upper")
																			.map((item, index) => (
																				<tr key={index}>
																					<td>{index + 1}</td>
																					<td>{item.jaw}</td>
																					<td>{item.note}</td>
																					<td>{item.step_no}</td>
																					<td>{item.value}</td>
																					<td>{item.between}</td>
																				</tr>
																			))}
																</tbody>
															</table>
														</>
													) : (
														<h6>Upper Jaw - No Data Available</h6>
													)}
													<br></br>
													{lowerJawsIprData ? (
														<>
															<h6>Lower Jaw </h6>
															<table className="table">
																<thead>
																	<tr>
																		<th>Sr. No</th>
																		<th>Jaw</th>
																		<th>Note</th>
																		<th>Step No.</th>
																		<th>Value</th>
																		<th>Between</th>
																	</tr>
																</thead>
																<tbody>
																	{patientIPRJsonData &&
																		patientIPRJsonData
																			.filter((item) => item.jaw === "lower")
																			.map((item, index) => (
																				<tr key={index}>
																					<td>{index + 1}</td>
																					<td>{item.jaw}</td>
																					<td>{item.note}</td>
																					<td>{item.step_no}</td>
																					<td>{item.value}</td>
																					<td>{item.between}</td>
																				</tr>
																			))}
																</tbody>
															</table>
														</>
													) : (
														<h6>Lower Jaw - No Data Available</h6>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
						{globalState.isLoggedIn && uuid && (
							<>
								<div className="HeaderNav-share">
									<button
										onClick={handleShareLink}
										className="share-btn"
										data-bs-toggle="modal"
										data-bs-target="#staticBackdrop"
										title="Share"
									>
										<i className="fa-solid fa-share-nodes btnIcon"></i>
										<span className="shareText">Share</span>
									</button>
								</div>
								<div
									className="modal fade"
									id="staticBackdrop"
									data-bs-backdrop="static"
									data-bs-keyboard="false"
									tabIndex="-1"
									aria-labelledby="staticBackdropLabel"
									aria-hidden="true"
								>
									<div className="modal-dialog modal-dialog-centered">
										<div className="modal-content">
											<div className="modal-header">
												<button
													type="button"
													className="btn-close"
													data-bs-dismiss="modal"
													aria-label="Close"
												></button>
											</div>
											<div className="modal-body">
												<textarea
													id="shareUrl"
													className="copyUrlTextarea"
													readOnly
													value={shareUrl}
												></textarea>
											</div>
											<div className="modal-footer">
												<button
													type="button"
													className="button"
													onClick={handleCopyClick}
												>
													Copy
												</button>
												<a
													href={shareUrl}
													target="_blank"
													rel="noopener noreferrer"
												>
													<button type="button" className="button">
														Open
													</button>
												</a>
											</div>
										</div>
									</div>
								</div>
							</>
						)}

						{globalState.isFileDropped && (
							<div className="HeaderNav-addipr">
								<button onClick={toggleUpperJawVisibility}>
									<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M0.717879 13.6071C1.43258 14.8606 2.51643 15.2456 3.18229 15.2456H23.7679C24.9471 15.2456 25.7229 14.5681 26.2181 13.6766C27.2099 11.891 25.6646 9.93193 23.6666 9.50799C15.745 7.82717 8.0695 8.53061 3.2525 9.52393C1.28895 9.92884 -0.275156 11.8654 0.717879 13.6071Z" fill="#FBABA5"></path>
										<path d="M17.4246 18.55C16.5165 18.6748 15.7132 18.6724 14.8435 18.553C13.7982 18.4096 13.1377 17.3518 13.343 16.27L13.731 14.2263C13.8882 13.3977 14.5171 12.7319 15.3258 12.6155C15.9137 12.5309 16.3299 12.5355 16.9149 12.6212C17.7393 12.742 18.3848 13.4133 18.5441 14.2525L18.9267 16.2686C19.132 17.3505 18.4709 18.4062 17.4246 18.55Z" fill="white" stroke="#C4C4C4" strokeWidth="0.411429"></path>
										<path d="M22.1998 17.5154C21.7108 17.5628 21.2513 17.5626 20.7774 17.5175C19.7419 17.4191 19.0797 16.3588 19.2841 15.2739L19.4927 14.1669C19.65 13.3323 20.2776 12.6526 21.0778 12.574C21.3553 12.5467 21.5963 12.5482 21.8725 12.577C22.6889 12.6619 23.3325 13.3481 23.4918 14.1933L23.6952 15.2725C23.8996 16.3572 23.2367 17.4151 22.1998 17.5154Z" fill="white" stroke="#C4C4C4" strokeWidth="0.411429"></path>
										<path d="M5.82138 17.5154C5.33248 17.5628 4.87296 17.5626 4.39902 17.5175C3.36356 17.4191 2.70129 16.3588 2.90574 15.2739L3.11435 14.1669C3.27163 13.3323 3.89926 12.6526 4.6994 12.574C4.97693 12.5467 5.21793 12.5482 5.49414 12.577C6.31052 12.6619 6.95416 13.3481 7.11344 14.1933L7.31682 15.2725C7.52123 16.3572 6.85828 17.4151 5.82138 17.5154Z" fill="white" stroke="#C4C4C4" strokeWidth="0.411429"></path>
										<path d="M11.7561 18.55C10.848 18.6747 10.0446 18.6724 9.17494 18.553C8.12965 18.4096 7.46919 17.3517 7.67451 16.27L8.06238 14.2263C8.21968 13.3978 8.84855 12.7319 9.65731 12.6155C10.2452 12.5309 10.6614 12.5355 11.2464 12.6212C12.0707 12.742 12.7163 13.4133 12.8755 14.2525L13.2582 16.2686C13.4635 17.3504 12.8024 18.4062 11.7561 18.55Z" fill="white" stroke="#C4C4C4" strokeWidth="0.411429"></path>
									</svg>
								</button>
								<button onClick={toggleLowerJawVisibility}>
									<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1.34163 13.5874C2.01761 12.4667 2.88234 12.103 3.44361 12.103H23.5894C24.647 12.2347 25.3626 12.9101 25.8357 13.8004C26.9787 15.9514 24.4937 17.8378 22.0771 17.5312C15.8592 16.7424 9.65574 17.0299 5.16202 17.5552C2.65998 17.8478 0.0405109 15.7444 1.34163 13.5874Z" fill="#FBABA5"></path>
										<path d="M9.0514 8.88877C10.1452 8.71294 11.0691 8.71766 12.1102 8.88586C13.005 9.03043 13.5674 9.93081 13.3925 10.852L12.9266 13.3066C12.7928 14.0118 12.2547 14.5755 11.5617 14.6897C10.8158 14.8125 10.365 14.8056 9.62235 14.6832C8.91627 14.5668 8.36448 13.9987 8.22889 13.2843L7.76745 10.8531C7.59254 9.93182 8.15579 9.03274 9.0514 8.88877Z" fill="white" stroke="#C4C4C4" strokeWidth="0.35122"></path>
										<path d="M4.37686 9.89265C5.04798 9.80632 5.65159 9.8077 6.2961 9.89027C7.18478 10.0041 7.74737 10.906 7.57351 11.8287L7.28389 13.3654C7.1504 14.0739 6.61416 14.6457 5.92553 14.7383C5.50474 14.7948 5.18874 14.7918 4.77003 14.734C4.06782 14.637 3.51792 14.0605 3.38269 13.3429L3.09756 11.8298C2.92369 10.9072 3.48715 10.0071 4.37686 9.89265Z" fill="white" stroke="#C4C4C4" strokeWidth="0.35122"></path>
										<path d="M20.4158 9.89265C21.087 9.80632 21.6906 9.8077 22.3351 9.89027C23.2238 10.0041 23.7864 10.906 23.6124 11.8287L23.3229 13.3654C23.1893 14.0739 22.6531 14.6457 21.9645 14.7383C21.5437 14.7948 21.2277 14.7918 20.809 14.734C20.1068 14.637 19.5569 14.0605 19.4216 13.3429L19.1365 11.8298C18.9626 10.9072 19.5261 10.0071 20.4158 9.89265Z" fill="white" stroke="#C4C4C4" strokeWidth="0.35122"></path>
										<path d="M14.5995 8.88877C15.6934 8.71294 16.6172 8.71766 17.6583 8.88586C18.5532 9.03043 19.1156 9.93081 18.9407 10.852L18.4748 13.3066C18.341 14.0118 17.8029 14.5755 17.1099 14.6897C16.364 14.8125 15.9131 14.8056 15.1706 14.6832C14.4644 14.5668 13.9126 13.9987 13.777 13.2843L13.3156 10.8531C13.1407 9.93182 13.7039 9.03274 14.5995 8.88877Z" fill="white" stroke="#C4C4C4" strokeWidth="0.35122"></path>
									</svg>
								</button>
								{/* <button onClick={toggleIprVisibility}>
									Show/Hide IPR
								</button> */}
								<button
									ref={globalState.addIprButtonHeaderRef}
									onClick={() =>
										globalState.actions.activateAddIpr(globalState)
									}
									className="HeaderNav-addipr__btn"
								>
									Add IPR
								</button>
								<button
									onClick={handlePrintPDF}
									className={`print-button ${isGenerating ? "generating" : ""}`}
									disabled={isGenerating}
								>
									{isGenerating ? (
										<>
											<i className="fa-solid fa-spinner fa-spin"></i>{" "}
											Generating...
										</>
									) : (
										<>
											<i className="fa-solid fa-print"></i> Generate PDF Report and JSON File
										</>
									)}
								</button>
								{/* <button
									onClick={() =>
										globalState.actions.generateJsonData(globalState)
									}
									className="HeaderNav-addipr__btn"
								>
									Generate Json Data
								</button>								 */}
							</div>
						)}

						<div className="HeaderNav-auth">
							{globalState.isLoggedIn ? (
								<>
									<div
										className={`HeaderNav-auth__welcome ${uuid ? "uuid" : ""}`}
									>
										Welcome,
										<span className="HeaderNav-auth__username">
											{globalState.nameOfUser ?? "N/A"}
										</span>
									</div>

									<button onClick={isLogout} className="HeaderNav-auth__logout">
										<i className="fa-solid fa-arrow-right-from-bracket btnIcon"></i>
										Logout
									</button>
								</>
							) : (
								<>
									<button
										className="HeaderNav-auth__login"
										onClick={handleLoginButton}
									>
										<i className="fa-solid fa-arrow-right-to-bracket btnIcon"></i>
										Login
									</button>

									{showLogin && (
										<Login appDivRef={appDivRef} loginType="header" />
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			{showPdfModal && (
				<PDFInfoModal
					onSubmit={handlePdfSubmit}
					onClose={() => setShowPdfModal(false)}
				/>
			)}
		</>
	);
}
