import * as THREE from "three";

let previousHighlightedTooth = null;

// Helper function to map tooth numbers
function mapToothNumber(toothNumber) {
    const num = parseInt(toothNumber, 10);
    if (num >= 1 && num <= 8) return 18 - (num - 1);
    if (num >= 9 && num <= 16) return 21 + (num - 9);
    if (num >= 17 && num <= 24) return 38 - (num - 17);
    if (num >= 25 && num <= 32) return 41 + (num - 25);
    return toothNumber; // Return original if not in range
}

function handleToothClick(event, threeViewer, setSelectedTooth, setBetweenValue, firstSelectedTooth, setFirstSelectedTooth) {
    if (!threeViewer.addIprDataBool) {
        return;
    }

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    const rect = threeViewer.renderer.domElement.getBoundingClientRect();
    mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    raycaster.setFromCamera(mouse, threeViewer.camera);

    let toothObjects = [];
    threeViewer.scene.traverse((obj) => {
        if (typeof obj.name === 'string' && 
            obj.name.startsWith("Tooth_") && 
            obj.visible && 
            obj.parent && 
            obj.parent.visible) {
            toothObjects.push(obj);
        }
    });

    const intersects = raycaster.intersectObjects(toothObjects, false);
    if (intersects.length > 0) {
        const clickedTooth = intersects[0].object;
        
        if (!clickedTooth.visible || !clickedTooth.parent.visible) {
            return;
        }

        const toothNumber = clickedTooth.name.split('_')[1];
        const mappedToothNumber = mapToothNumber(toothNumber);
        setSelectedTooth(clickedTooth.name);

        // Handle first tooth selection
        if (!firstSelectedTooth) {
            setFirstSelectedTooth(mappedToothNumber); // Store first tooth number in state
            setBetweenValue(mappedToothNumber); // Set first tooth number
        } 
        // Handle second tooth selection
        else if (clickedTooth.name !== `Tooth_${firstSelectedTooth}`) {
            setBetweenValue(`${firstSelectedTooth}-${mappedToothNumber}`);
            setFirstSelectedTooth(null); // Reset for next selection
        }

        // Store original material if not already stored
        if (!clickedTooth.userData.originalMaterial) {
            clickedTooth.userData.originalMaterial = clickedTooth.material.clone();
        }

        // Create highlight material with light blue
        const highlightMaterial = new THREE.MeshStandardMaterial({
            color: clickedTooth.material.color,
            roughness: 0.5,
            metalness: 0.5,
            transparent: true,
            opacity: 0.8,
            emissive: new THREE.Color(0xADD8E6),
            emissiveIntensity: 0.4,
            side: THREE.DoubleSide
        });

        // Remove highlight from previous tooth if different from first selected tooth
        if (previousHighlightedTooth && previousHighlightedTooth.name !== `Tooth_${firstSelectedTooth}`) {
            previousHighlightedTooth.material = previousHighlightedTooth.userData.originalMaterial;
        }

        // Apply highlight
        clickedTooth.material = highlightMaterial;
        previousHighlightedTooth = clickedTooth;
    }
}

export default handleToothClick;

// Add a function to reset the selection state and remove highlights from all teeth
export const resetToothSelection = (threeViewer, setFirstSelectedTooth) => {
    threeViewer.scene.traverse((obj) => {
        if (typeof obj.name === 'string' && obj.name.startsWith("Tooth_") && obj.userData.originalMaterial) {
            obj.material = obj.userData.originalMaterial;
        }
    });
    previousHighlightedTooth = null;
    setFirstSelectedTooth(null);
};
