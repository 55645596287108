import React, { useState, useEffect } from "react";
import "./PDFInfoModal.css";
import { useLocation } from "react-router-dom";

const PDFInfoModal = ({ onClose, onSubmit }) => {
    const location = useLocation();
    const [formData, setFormData] = useState({
        patientUploadName: "",
        doctorUploadName: "",
        patientUploadId: "",
        treatmentPlanVersionUpload: "",
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setFormData({
            patientUploadId: params.get("patientUploadId") || "",
            treatmentPlanVersionUpload: params.get("treatmentPlanVersionUpload") || "",
            patientUploadName: params.get("patientUploadName") || "",
            doctorUploadName: params.get("doctorUploadName") || "",
        });
    }, [location]);

    return (
        <div className="pdf-modal-overlay">
            <div className="pdf-modal">
                <h2>Enter PDF Information</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Patient Name:</label>
                        <input
                            type="text"
                            value={formData.patientUploadName}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    patientUploadName: e.target.value,
                                })
                            }
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Patient ID:</label>
                        <input
                            type="text"
                            value={formData.patientUploadId}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    patientUploadId: e.target.value,
                                })
                            }
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Doctor Name:</label>
                        <input
                            type="text"
                            value={formData.doctorUploadName}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    doctorUploadName: e.target.value,
                                })
                            }
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Treatment Plan Version:</label>
                        <input
                            type="text"
                            value={formData.treatmentPlanVersionUpload}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    treatmentPlanVersionUpload: e.target.value,
                                })
                            }
                            required
                        />
                    </div>
                    <div className="modal-buttons">
                        <button type="submit">Generate PDF</button>
                        <button type="button" onClick={onClose}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default PDFInfoModal;
