import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import viewManager from "../viewManager.js";
import {
	CSS2DRenderer,
	CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer.js";

class ThreeViewer {
	constructor(params) {
		/**
		 * This class is a wrapper for Three.js
		 * @param {HTMLElement} params.element
		 * @param {string} params.bgColor
		 */

		this.params = params;
		this.element = params.element;

		// Create width and height properties on the element
		this.canvasWidth = this.element.clientWidth;
		this.canvasHeight = this.element.clientHeight;

		// Mouse
		this.mouse = new THREE.Vector2();

		// Array for objects that can be picked
		this.pickingObjects = [];

		// For checking click events
		this.timer = 0;
		this.delay = 100;
		this.prevent = false;

		this.clock = new THREE.Clock();
		this.playMode = false;
		this.viewManager = new viewManager(this);
		this.animationState = false;
		this.speed = 12;
		this.noOfModels = 0;
		this.threeViewerRight = null;
		this.addIprDataBool = false;
		this.globalState = null;
	}
	initViewer() {
		let scopeViewer = this;

		// Create scene, camera and renderer
		scopeViewer.scene = new THREE.Scene();
		scopeViewer.scene.background = new THREE.Color(
			scopeViewer.params.bgColor || "rgb(112, 114, 115)"
		);

		// Create a camera, scene, renderer
		// scopeViewer.camera = new THREE.PerspectiveCamera(75, scopeViewer.canvasWidth / scopeViewer.canvasHeight, 0.1, 10000)
		// scopeViewer.camera.position.z = 30
		// scopeViewer.scene.add(scopeViewer.camera)
		// Create a camera, scene, renderer
		scopeViewer.camera = new THREE.OrthographicCamera(
			scopeViewer.canvasWidth / -2,
			scopeViewer.canvasWidth / 2,
			scopeViewer.canvasHeight / 2,
			scopeViewer.canvasHeight / -2,
			1,
			1000
		);
		scopeViewer.camera.position.set(0, 0, 150);
		scopeViewer.camera.zoom = 10;
		scopeViewer.camera.layers.enableAll();
		scopeViewer.camera.updateProjectionMatrix();
		scopeViewer.scene.add(scopeViewer.camera);

		var ambientLight = new THREE.AmbientLight(0xffffff, 0.5); // soft white light
		scopeViewer.scene.add(ambientLight);

		// light attached to camera
		var spotLightCamera = new THREE.SpotLight(0xf2dbdb, 0.5);
		spotLightCamera.castShadow = true;
		spotLightCamera.target.position.set(0, 0, -1);
		spotLightCamera.add(spotLightCamera.target);
		spotLightCamera.position.set(-0, -0, 50);
		scopeViewer.camera.add(spotLightCamera);

		scopeViewer.renderer = new THREE.WebGLRenderer({
			antialias: true,
		});
		this.renderer.useLegacyLights = true;
		scopeViewer.renderer.setSize(
			scopeViewer.canvasWidth,
			scopeViewer.canvasHeight
		);

		// Add canvas to the DOM
		scopeViewer.element.innerHTML = "";
		scopeViewer.element.appendChild(scopeViewer.renderer.domElement);
		scopeViewer.renderer.domElement.id = "mainCanvas";

		// Create controls
		scopeViewer.controls = new OrbitControls(
			scopeViewer.camera,
			scopeViewer.renderer.domElement
		);

		window.addEventListener(
			"resize",
			() => {
				scopeViewer.onWindowResize(scopeViewer);
			},
			false
		);
		scopeViewer.renderer.domElement.addEventListener("click", () => {
			scopeViewer.onclick(scopeViewer);
		});
		scopeViewer.renderer.domElement.addEventListener(
			"pointermove",
			(event) => {
				scopeViewer.onMouseMove(event, scopeViewer);
			}
		);
		scopeViewer.setUpLabelRenderer();
		scopeViewer.animate();
		this.addGridHelper();
	}
	onWindowResize(scopeViewer) {
		scopeViewer.clientWidth = scopeViewer.element.clientWidth;
		scopeViewer.clientHeight = scopeViewer.element.clientHeight;
		scopeViewer.canvasWidth = scopeViewer.element.clientWidth;
		scopeViewer.canvasHeight = scopeViewer.element.clientHeight;
		scopeViewer.camera.left = -scopeViewer.clientWidth / 2;
		scopeViewer.camera.right = scopeViewer.clientWidth / 2;
		scopeViewer.camera.top = scopeViewer.clientHeight / 2;
		scopeViewer.camera.bottom = -scopeViewer.clientHeight / 2;
		scopeViewer.camera.updateProjectionMatrix();
		scopeViewer.renderer.setSize(
			scopeViewer.clientWidth,
			scopeViewer.clientHeight
		);
        this.resizeLabel()
	}
	render() {
		let scopeViewer = this;
		// if(this.playMode) {
		//     let animation  = new SubSetupGroup(scopeViewer)
		//     animation.objectAnimation()
		// }

		const elapsedTime = scopeViewer.clock.getElapsedTime();
		
		// Ensure the Euler rotation order is set correctly
        if (this.camera.rotation.order === undefined) {
            this.camera.rotation.order = 'XYZ';
        }

		scopeViewer.renderer.render(scopeViewer.scene, scopeViewer.camera);
		scopeViewer.controls.update();
		this.labelRenderer.render(scopeViewer.scene, scopeViewer.camera);
	}

	animate() {
		let scopeViewer = this;
		requestAnimationFrame(scopeViewer.animate.bind(scopeViewer));
		this.labelRenderer.render(scopeViewer.scene, scopeViewer.camera);
		scopeViewer.render();
	}
	onclick(scopeViewer) {
		scopeViewer.timer = setTimeout(function () {
			if (!scopeViewer.prevent) {
				// checkIntersectionclick(event);
				if (scopeViewer.intersectionCallback) {
					scopeViewer.intersectionCallback();
				}
			}
			if (scopeViewer.addIprDataBool) {
				while (scopeViewer.scene.getObjectByName("refSphereForIpr")) {
					scopeViewer.globalState.actions.removeObjWithChildren(
						scopeViewer.scene.getObjectByName("refSphereForIpr")
					);
				}
				scopeViewer.attachIprData(scopeViewer);
				// scopeViewer.globalState.setIprOpen(true)
				// scopeViewer.addIprDataBool = false
			}
			scopeViewer.prevent = false;
		}, scopeViewer.delay);
	}

	onMouseMove(event, scopeViewer) {
		scopeViewer.mouse.x =
			((event.clientX -
				scopeViewer.element.getBoundingClientRect().left) /
				scopeViewer.element.clientWidth) *
				2 -
			1;
		scopeViewer.mouse.y =
			-(
				(event.clientY -
					scopeViewer.element.getBoundingClientRect().top) /
				scopeViewer.element.clientHeight
			) *
				2 +
			1;

		scopeViewer.prevent = true;
		setTimeout(() => {
			scopeViewer.prevent = false;
		}, scopeViewer.delay);
	}
	objectAnimation(
		frame,
		noOfAnimatedObjects,
		playbackSpeed,
		setCurrentSetup
	) {
		if (frame < noOfAnimatedObjects * playbackSpeed - 1) {
			frame++;
		} else {
			frame = 0;
		}

		var groupNumber = Math.floor(frame / playbackSpeed) + 1;

		if (noOfAnimatedObjects > 0) {
			for (let i = 1; i <= noOfAnimatedObjects; i++) {
				if (this.scene.getObjectByName("mandibular").visible == true) {
					if (
						this.scene.getObjectByName("mandibular_" + i.toString())
					) {
						this.scene.getObjectByName(
							"mandibular_" + i.toString()
						).visible = i === groupNumber;
					} else {
						for (let j = i; j >= 1; j--) {
							if (
								this.scene.getObjectByName(
									"mandibular_" + j.toString()
								) &&
								j < groupNumber
							) {
								this.scene.getObjectByName(
									"mandibular_" + j.toString()
								).visible = true;
								break;
							}
						}
					}
				}
				if (this.scene.getObjectByName("maxillary").visible == true) {
					if (
						this.scene.getObjectByName("maxillary_" + i.toString())
					) {
						this.scene.getObjectByName(
							"maxillary_" + i.toString()
						).visible = i === groupNumber;
					} else {
						for (let j = i; j >= 1; j--) {
							if (
								this.scene.getObjectByName(
									"maxillary_" + j.toString()
								) &&
								j < groupNumber
							) {
								this.scene.getObjectByName(
									"maxillary_" + j.toString()
								).visible = true;
								break;
							}
						}
					}
				}
				if (i === groupNumber) {
					setCurrentSetup(i - 1);
				}
			}
		}
		if (this.animationState) {
			requestAnimationFrame(() =>
				this.objectAnimation(
					frame,
					this.noOfModels,
					this.speed,
					setCurrentSetup
				)
			);
		}
	}

	addGridHelper() {
		this.gridHelper = new THREE.GridHelper(
			4000,
			400,
			new THREE.Color("rgb(20,20,20)"),
			new THREE.Color("rgb(20,20,20)")
		);
		this.gridHelper.name = "grid1";
		this.camera.add(this.gridHelper);
		this.gridHelper.rotation.x = Math.PI / 2;
		this.gridHelper.position.z = -1;
		this.gridHelper.material.transparent = true;
		this.gridHelper.renderOrder = 0;
		this.gridHelper.visible = false;

		this.gridHelper2 = new THREE.GridHelper(
			4000,
			4000,
			new THREE.Color("rgb(18,18,18)"),
			new THREE.Color("rgb(18,18,18)")
		);
		this.gridHelper2.name = "grid2";
		this.camera.add(this.gridHelper2);
		this.gridHelper2.rotation.x = Math.PI / 2;
		this.gridHelper2.position.z = -1;
		this.gridHelper2.material.transparent = true;
		this.gridHelper2.renderOrder = 0;
		this.gridHelper2.visible = false;

		if (this.camera.zoom >= 60) {
			this.gridHelper.scale.set(0.1, 0.1, 0.1);
			this.gridHelper2.scale.set(0.1, 0.1, 0.1);
		} else {
			this.gridHelper.scale.set(1, 1, 1);
			this.gridHelper2.scale.set(1, 1, 1);
		}
		this.gridHelper.material.opacity = 0.2;
		this.gridHelper2.material.opacity = 0.1;
	}

	setUpLabelRenderer() {
		let scopeViewer = this;
		this.labelRenderer = new CSS2DRenderer();
		this.labelRenderer.domElement.id = "2dContent";
		this.labelRenderer.domElement.style.position = "absolute";
		this.labelRenderer.domElement.style.top = "0px";
		this.labelRenderer.domElement.style.pointerEvents = "none";
		this.element.appendChild(this.labelRenderer.domElement);
		this.labelRenderer.setSize(
			scopeViewer.element.clientWidth,
			scopeViewer.element.clientHeight
		);
		scopeViewer.resizeLabel();
	}
	resizeLabel() {
		let scopeViewer = this;
        this.labelRenderer.setSize(scopeViewer.element.clientWidth, scopeViewer.element.clientHeight);
        this.labelRenderer.domElement.style.top = '0px';
	}

	attachIprData(scopeViewer) {
		var raycaster = new THREE.Raycaster();
		raycaster.setFromCamera(scopeViewer.mouse, scopeViewer.camera);
		var intersectionclick = raycaster.intersectObject(
			scopeViewer.scene.children[2],
			true
		);
		if (intersectionclick.length > 0) {
			// make a sphere at the intersection point and add it to the threeviewer scene
			let sphereGeo = new THREE.SphereGeometry(0.5, 32, 32);
			let sphereMat = new THREE.MeshBasicMaterial({
				color: new THREE.Color("#2d5d9f"),
			});
			let sphere = new THREE.Mesh(sphereGeo, sphereMat);
			sphere.position.copy(intersectionclick[0].point);
			sphere.name = "refSphereForIpr";
			scopeViewer.scene.add(sphere);
			if (
				intersectionclick[0].object.name == "lower" ||
				intersectionclick[0].object.parent.name.startsWith(
					"mandibular"
				) ||
				intersectionclick[0].object.parent.name == "Mandibular"
			)
				scopeViewer.globalState.setIprJawValue("lower");
			if (
				intersectionclick[0].object.name == "upper" ||
				intersectionclick[0].object.parent.name.startsWith(
					"maxillary"
				) ||
				intersectionclick[0].object.parent.name == "Maxillary"
			) {
				scopeViewer.globalState.setIprJawValue("upper");
			}
			this.globalState.setIprLocationValue([
				sphere.position.x,
				sphere.position.y,
				sphere.position.z,
			]);
		}
	}
}

export { ThreeViewer };
