import React, { useCallback, useContext, useEffect, useState } from "react";
import * as THREE from "three";
import StateContext from "../../context/stateGlobal/StateContext";
import "./DragDrop.css";
import IprLoadingManager from "./IprLoadingManager";
import { createAccumulator, generateUniqueId } from "./utils/generateJsonData";
import Cookies from "universal-cookie";
import handleToothClick, { resetToothSelection } from "./handleToothClick";

const FileDropZone = () => {
	const globalState = useContext(StateContext);
	const [selectedTooth, setSelectedTooth] = useState(null);
	const [firstSelectedTooth, setFirstSelectedTooth] = useState(null);

	// Define setBetweenValue function
	const setBetweenValue = (value) => {
		if (globalState.iprBetweenRef.current) {
			globalState.iprBetweenRef.current.value = value;
		}
	};

	const objFiles = [];
	const tempObjFiles = [];
	const handleDrop = useCallback(
		async (event) => {
			globalState.dragAndDropRef.current.style.display = "none";
			globalState.setIsFileDropped(true);
			event.preventDefault();
			const items = event.dataTransfer.items;
			globalState.loadingDivRef.current.style.display = "flex";
			globalState.footerRef.current.style.display = "flex";

			for (var i = 0; i < items.length; i++) {
				var item = items[i].webkitGetAsEntry();
				if (item) {
					traverseFileTree(item);
				}
			}

			// Initialize the LoadingManger3D class
			const loadingManager = new IprLoadingManager();

			// Load all models using the LoadingManger3D class
			setTimeout(async () => {
				const groupedByFileNumber = {};
				groupAllModelData(groupedByFileNumber);
				const keys = Object.keys(groupedByFileNumber);

				for (let i = 0; i < keys.length; i++) {
					const fileNumber = keys[i];
					const fileData = groupedByFileNumber[fileNumber];
					let modelObj = {
						Mandibular: "",
						Maxillary: "",
					};
					fileData.forEach((toothModel) => {
						if (toothModel.FileName === "Mandibular.obj") {
							modelObj.Mandibular = toothModel.File;
						}
						if (toothModel.FileName === "Maxillary.obj") {
							modelObj.Maxillary = toothModel.File;
						}
					});
					objFiles.push(modelObj);
				}
				let objectGrp3D = await loadingManager.loadAllModels(
					objFiles,
					window.threeViewer,
					globalState,
				);
				let mandibularModels =
					objectGrp3D.getObjectByName("mandibular").children.length;
				let maxillaryModels =
					objectGrp3D.getObjectByName("maxillary").children.length;
				let maxModels = Math.max(mandibularModels, maxillaryModels);
				globalState.setSubsetGroup(maxModels);
				globalState.setCurrentSetup(0);
			}, 1000);
		},
		[globalState],
	);

	const handleFileInputChange = useCallback(
		async (event) => {
			globalState.dragAndDropRef.current.style.display = "none";
			globalState.loadingDivRef.current.style.display = "flex";
			globalState.footerRef.current.style.display = "flex";
			globalState.setIsFileDropped(true);

			// Use event.target.files for file inputs.
			const files = event.target.files;

			globalState.loadingDivRef.current.style.display = "flex";

			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				if (file.name === "Mandibular.obj" || file.name === "Maxillary.obj") {
					let obj = {
						File: file,
						FileNumber: file.webkitRelativePath.split("/")[1],
						FileName: file.name,
					};
					tempObjFiles.push(obj); // Add the .obj file to the array
				}
			}

			// Initialize the LoadingManger3D class
			const loadingManager = new IprLoadingManager();

			// Load all models using the LoadingManger3D class
			setTimeout(async () => {
				const groupedByFileNumber = {};
				groupAllModelData(groupedByFileNumber);
				const keys = Object.keys(groupedByFileNumber);

				for (let i = 0; i < keys.length; i++) {
					const fileNumber = keys[i];
					const fileData = groupedByFileNumber[fileNumber];
					let modelObj = {
						Mandibular: "",
						Maxillary: "",
					};
					fileData.forEach((toothModel) => {
						if (toothModel.FileName === "Mandibular.obj") {
							modelObj.Mandibular = toothModel.File;
						}
						if (toothModel.FileName === "Maxillary.obj") {
							modelObj.Maxillary = toothModel.File;
						}
					});
					objFiles.push(modelObj);
				}
				let objectGrp3D = await loadingManager.loadAllModels(
					objFiles,
					window.threeViewer,
					globalState,
				);
				let mandibularModels =
					objectGrp3D.getObjectByName("mandibular").children.length;
				let maxillaryModels =
					objectGrp3D.getObjectByName("maxillary").children.length;
				let maxModels = Math.max(mandibularModels, maxillaryModels);
				globalState.setSubsetGroup(maxModels);
				globalState.setCurrentSetup(0);
			}, 1000);
		},
		[globalState],
	);

	const traverseFileTree = (item, path = "") => {
		if (item.isFile) {
			item.file(function (file) {
				// Check if the file is an OBJ file
				if (file.name.endsWith(".obj")) {
					if (file.name === "Mandibular.obj" || file.name === "Maxillary.obj") {
						let obj = {
							File: file,
							FileNumber: path.split("/")[1],
							FileName: file.name,
						};
						tempObjFiles.push(obj); // Add the .obj file to the array
					}
				}
			});
		} else if (item.isDirectory) {
			var dirReader = item.createReader();
			dirReader.readEntries(function (entries) {
				for (var i = 0; i < entries.length; i++) {
					traverseFileTree(entries[i], path + item.name + "/");
				}
			});
		}
	};
	const groupAllModelData = (obj) => {
		for (let i = 0; i < tempObjFiles.length; i++) {
			const fileObj = tempObjFiles[i];

			if (!obj[fileObj.FileNumber]) {
				obj[fileObj.FileNumber] = [];
			}
			obj[fileObj.FileNumber].push(fileObj);
		}
	};

	useEffect(() => {
		if (globalState.IprJawValue) {
			globalState.iprJawRef.current.value = globalState.IprJawValue;
		}
	}, [globalState.IprJawValue]);

	useEffect(() => {
		if (globalState.isIprOpen) {
			// Enable IPR interaction only when modal is open
			globalState.viewer.addIprDataBool = true;
		} else {
			// Disable IPR interaction when modal is closed
			globalState.viewer.addIprDataBool = false;
			// Clean up any existing IPR spheres
			if (globalState.viewer.scene) {
				const sphere =
					globalState.viewer.scene.getObjectByName("refSphereForIpr");
				if (sphere) {
					globalState.actions.removeObjWithChildren(sphere);
				}
			}
		}
	}, [globalState.isIprOpen]);

	useEffect(() => {
		if (!window.threeViewer || !window.threeViewer.renderer || !globalState.addingIprDataBool) return;

		const handleToothClickWrapper = (event) => 
			handleToothClick(
				event, 
				window.threeViewer, 
				setSelectedTooth, 
				setBetweenValue, 
				firstSelectedTooth, 
				setFirstSelectedTooth // Pass state and state setter for first selected tooth
			);

		window.threeViewer.renderer.domElement.addEventListener('dblclick', handleToothClickWrapper);

		return () => {
			if (window.threeViewer && window.threeViewer.renderer) {
				window.threeViewer.renderer.domElement.removeEventListener('dblclick', handleToothClickWrapper);
			}
		};
	}, [window.threeViewer, firstSelectedTooth]);

	const handleBetweenInput = (e) => {
		let value = e.target.value.replace(/\D/g, ""); // Remove non-digits

		if (value.length > 2) {
			// If more than 2 digits, format as XX-XX
			value = value.slice(0, 2) + "-" + value.slice(2, 4);
		}

		// Update the input value
		globalState.iprBetweenRef.current.value = value;
	};

	const handleValueInput = (e) => {
		let value = e.target.value.replace(/[^\d.]/g, ""); // Remove non-digits and non-decimal points

		// If the input starts with a digit, prefix with "0."
		if (value && !value.startsWith("0.")) {
			value = "0." + value.replace(".", "");
		}

		// Ensure the value is between 0.1 and 0.9
		const numValue = parseFloat(value);
		if (numValue && (numValue < 0.1 || numValue > 0.9)) {
			return;
		}

		// Update the input value
		globalState.iprValueRef.current.value = value;
	};

	let iprObjectAccumulator = createAccumulator();

	const validateIprBetween = (inputString) => {
		const formatRegex = /^[1-9]{2}-[1-9]{2}$/; // Match "two digits-two digits" where digits are 1-9
		return formatRegex.test(inputString);
	};

	const validateIprValue = (inputString) => {
		const formatRegex = /^0\.[1-9]$/; // Regular expression to match a single decimal between 0.1 and 0.9
		return formatRegex.test(inputString);
	};

	const AddIprData = () => {
		// Reset the interaction state first
		globalState.viewer.addIprDataBool = true; // Keep it true until successful submission

		// Validation checks
		if (!globalState.iprJawRef.current.value) {
			alert("Please select the tooth to add IPR");
			// Reset interaction flags to allow new selection
			if (globalState.viewer.scene.getObjectByName("refSphereForIpr")) {
				globalState.actions.removeObjWithChildren(
					globalState.viewer.scene.getObjectByName("refSphereForIpr"),
				);
			}
			return;
		}

		if (!validateIprBetween(globalState.iprBetweenRef.current.value)) {
			alert(
				"Please enter In between value in the correct format (e.g., 11-21).",
			);
			return;
		}

		if (!validateIprValue(globalState.iprValueRef.current.value)) {
			alert("Please enter IPR value in the correct range (e.g., 0.1 - 0.9).");
			return;
		}

		// If we reach here, all validations passed
		globalState.viewer.addIprDataBool = false;

		let obj = {
			step_no: globalState.currentSetup,
			location: globalState.iprLocationValue,
			jaw: globalState.iprJawRef.current.value,
			note: globalState.iprNoteRef.current.value,
			value: globalState.iprValueRef.current.value,
			id: generateUniqueId(),
			between: globalState.iprBetweenRef.current.value,
		};

		iprObjectAccumulator.addObject(obj);
		globalState.setIprJsonObj(iprObjectAccumulator.getMainObject());
		globalState.setIprOpen(false);

		while (globalState.viewer.scene.getObjectByName("refSphereForIpr")) {
			globalState.actions.removeObjWithChildren(
				globalState.viewer.scene.getObjectByName("refSphereForIpr"),
			);
		}
		while (globalState.viewer.scene.getObjectByName("iprGroup")) {
			globalState.actions.removeObjWithChildren(
				globalState.viewer.scene.getObjectByName("iprGroup"),
			);
		}

		globalState.setIprData(iprObjectAccumulator.getMainObject());
		setTimeout(() => {
			globalState.actions.iprViewerForUploader(true);
			globalState.setIprBool(true);
		}, 500);

		globalState.addIprButtonHeaderRef.current.removeAttribute("disabled");

		// Reset tooth selection and remove highlights
		resetToothSelection(window.threeViewer, setFirstSelectedTooth);
	};

	const closeAddIprDataPanel = () => {
		globalState.setIprOpen(false);
		if (globalState.viewer.scene) {
			resetToothSelection(window.threeViewer, setFirstSelectedTooth); // Pass threeViewer and setFirstSelectedTooth to resetToothSelection
			while (globalState.viewer.scene.getObjectByName("refSphereForIpr")) {
				globalState.actions.removeObjWithChildren(
					globalState.viewer.scene.getObjectByName("refSphereForIpr"),
				);
			}
			globalState.viewer.addIprDataBool = false;
			globalState.addIprButtonHeaderRef.current.removeAttribute("disabled");
			globalState.iprBetweenRef.current.value = ''; // Clear the between field
		}
	};

	const cookies = new Cookies();
	const userRole = cookies.get("userRole", { path: "/" });

	return (
		<>
			{globalState.isLoggedInForIpr && userRole === "uploader" ? (
				<div
					className="addIprInput drop-zone"
					onDrop={handleDrop}
					ref={globalState.dragAndDropRef}
					onDragOver={(e) => e.preventDefault()}
				>
					<p>Drag and drop your folder here or </p>
					<input
						type="file"
						webkitdirectory="true"
						directory="true"
						onChange={handleFileInputChange}
					/>
				</div>
			) : (
				<h3 className="no-permission">
					You don't have permission to access this page.
				</h3>
			)}

			{globalState.isIprOpen && (
				<div className="ipr-popup">
					<i
						className="fa-solid fa-xmark closeIcon"
						onClick={() => closeAddIprDataPanel()}
					></i>
					<h2>Add IPR Data</h2>
					<div
						className="spanIprForText"
						ref={globalState.spanRefForToothSelectionInIpr}
					>
						Please select the tooth to add IPR
					</div>
					<div className="input-container">
						<label htmlFor="setUpNo" className="form-label">
							Step No:
						</label>
						<input
							type="number"
							id="setUpNo"
							readOnly
							placeholder="Step No"
							defaultValue={globalState.currentSetup}
						/>
					</div>
					<div className="input-container">
						<label htmlFor="jaw" className="form-label">
							Jaw:
						</label>
						<input
							type="text"
							id="Jaw"
							readOnly
							ref={globalState.iprJawRef}
							defaultValue={globalState.iprJawValue}
							placeholder="Jaw"
						/>
					</div>
					<div className="input-container">
						<label htmlFor="note" className="form-label">
							Note:
						</label>
						<input
							type="text"
							id="note"
							ref={globalState.iprNoteRef}
							placeholder="Note"
						/>
					</div>
					<div className="input-container">
						<label htmlFor="value" className="form-label">
							Value:
						</label>
						<input
							type="text"
							id="value"
							ref={globalState.iprValueRef}
							placeholder="0.1-0.9"
							onChange={handleValueInput}
							maxLength="3"
						/>
					</div>
					<div className="input-container">
						<label htmlFor="between" className="form-label">
							Between:
						</label>
						<input
							type="text"
							id="between"
							ref={globalState.iprBetweenRef}
							placeholder="11-21"
							maxLength="5"
							onChange={handleBetweenInput}
						/>
					</div>
					<div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
						<button className="enter-button" onClick={AddIprData}>
							Done
						</button>
						<button
						className="enter-button"
						onClick={() => {
							AddIprData();
							globalState.actions.activateAddIpr(globalState)
							}
						}
						>
							Next
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default FileDropZone;
